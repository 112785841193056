/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
 

*{border: 0px; outline: 0px!important;}

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.card{
  border: 1px solid #e5e5e5!important;
}
.breadcrumb{margin-bottom: 10px!important; padding: 0px 10px 14px 0px!important;}

.breadcrumb-left{
  float: left;
  display: block; 
  font-size: 16px;
  font-weight: 600;
}

.breadcrumb-right{
float:right; display:block
}

.breadcrumb-h6{
  color:#495057;
}

.breadcrumb-h6:hover{
  color:#048eb8;
}

.breadcrumb-selected{
  color: #048eb8
}

.btn-outline-secondary-green{
  color: #44c944!important;
  border-color: #44c944!important;
}

.btn-outline-secondary-grey{
  color: #ccc!important;
  border-color: #ccc!important;
  cursor: default;
  pointer-events: none
}

.btn-outline-secondary-green:hover{
  background-color: #44c944!important;
  border-color: #44c944!important;
  color:#fff!important
}

.btn-outline-secondary-brown:hover{
background-color: #793914!important;
border-color: #793914!important;
color:#fff!important
}

.btn-outline-secondary-brown{
color: #793914!important;
border-color: #793914!important;
}



.btn-outline-secondary-red{
  color: #048eb8!important;
  border-color: #048eb8!important;
}
.btn-outline-secondary-red:hover{
  background-color: #048eb8!important;
  border-color: #048eb8!important;
  color:#fff!important
}

.btn-outline-secondary-grey{
  color: #ccc!important;
  border-color: #ccc!important;
}
.btn-outline-secondary-grey:hover{
  background-color: #555!important;
  border-color: #555!important;
  color:#fff!important
}
.btn-outline-secondary-orange{
  color: red!important;
  border-color: red!important;
}
.btn-outline-secondary-orange:hover{
  background-color: red!important;
  border-color: red!important;
  color:#fff!important
}

.btn-primary {
  color: #fff;
  background-color: #FA751A!important;
  border-color: #FA751A!important;
}


.btn-primary:hover {
color: #FA751A!important;
background-color: #fff!important;
border-color: #FA751A!important;
}

.upload-box{
  height: 205px!important;
  width: 63%!important;
  margin-left: 18%!important;
  border: 1px dashed #f37721!important; position: relative;
}

.upload-icon{
  background-color: #fff!important;
  opacity: 0!important; width: 100%;
  height: 205px!important; position: absolute;left: 0;
}

.upload-icons{
  font-size: 131px!important; 
  margin-top: 25px!important;
  color: #f37721!important; cursor: pointer;
}

.upload-heading{ 
  font-size: 15px!important; cursor: pointer;  
}

 


.back-yellow{
  background: linear-gradient(120deg, #d3d205, #f9bb11);
  height: 106px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  margin-top: -8px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
}


.back-green{
  // background: linear-gradient(120deg, rgb(66, 154, 67), rgb(111, 183, 87));
  background-image: linear-gradient(to right top, #048eb8, #0784a9, #0b799b, #0e6f8d, #10657f);
  height: 106px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  margin-top: -8px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
}

.back-red{
  // background: linear-gradient(120deg, rgb(66, 154, 67), rgb(111, 183, 87));
  background-image: linear-gradient(to right top,rgba(233,111,111,1) 0%, rgba(227,82,82,1) 25%, rgba(238,31,8,1) 68%);
  height: 106px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  margin-top: -8px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
}

// .page-content {
//   padding: calc(70px + 24px) calc(24px / 2) calc(24px / 2)!important;
// }

.form-check-input:checked {
  background-color: #f37721!important;
  border-color: #f37721!important;
}

.back-blue{
  background-image: linear-gradient(to right top, #048eb8, #0784a9, #0b799b, #0e6f8d, #10657f);
  height: 84px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  margin-top: -8px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
}

.back-grey{
  background-image: linear-gradient(to right top, #808181, #696b6b, #545655, #40423f, #2d2e2b);
  height: 104px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  margin-top: -8px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
}

.back-orange{
  background-image: linear-gradient(to right top, #f37721, #dd6b1d, #c86018, #b35514, #9f4a10);
  // background-image: linear-gradient(to right top, #048eb8, #0784a9, #0b799b, #0e6f8d, #10657f);
  height: 104px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  margin-top: -8px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
}

.back-blush{
  background-image: linear-gradient(to right top, #09095a, #221878, #382997, #4d39b8, #634bda);
  height: 104px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  margin-top: -8px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
}

.pad-20{
  padding-top:20px;
}

.he-147{
  height: 113px;
}

.mar10{
  margin-left: -10px!important;
  margin-top: -10px;
  margin-bottom: 1rem!important;
}

.small{
  height: 15px;
  background-color: rgb(66, 154, 67);
  border-radius: 10px;
}


.small-h4{
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1px;
}
.ht-360{
  height: 360px;
}
.heading{
  font-weight: 600;
  font-size: 11px!important;
  margin-top: -12px;
  margin-left: -10px;
}


.heading-h4{
  float: left; 
  margin-left: 12px; 
  margin-top: 6px;
  }

  .table-scroll{
    height: 250px;
    overflow-y: scroll;
}

.img-driver{
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 93px;
}


.big{
  height: 15px;
  background-color: #e5e5e5;
  border-radius: 10px;
}

.mr-150{
  display: flex; 
  height: 104px;
 }

 .mr-40{
  display: flex; 
  height: 40px;
 }

 .form-check-input:checked {
  background-color: #048eb8!important;
  border-color: #048eb8!important;
}

 .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #048eb8!important;
  border-color: #048eb8!important;
}


.engine-right{
  float: left;
  display: flex;
  width: 40%!important;
  padding: 4px 20px 20px 30px;
}

.engine-left{
  display: flex;
  width: 56%!important;
  float: left;
}


.engine-right-driving{
  float: left;
  display: flex;
  width: 40%!important;
  padding: 4px 20px 20px 0px;
  margin-left: -12px!important;
}


.engine-load{
  font-size: 24px;
  text-align: center;
  color: #fff;
  padding-top: 14px;
}

.engine-loads{
  padding: 18px 18px 18px 15px;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

.vin{
  height: 30px;
  width: 85px;
  text-align: center;
  padding: 4px 7px 7px 5px;
  background-color: #00d5ce;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  float: right;
}

.vin-failed{
  height: 30px;
  width: 85px;
  text-align: center;
  padding: 4px 7px 7px 7px;
  background-color: #ff5947;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  float: right;
}
.enroll-failed{
  height: 30px;
  width: 85px;
  text-align: center;
  padding: 4px 7px 7px 7px;
  background-color: #645d07;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  float: right;
}
.request-pending{
  height: 30px;
  width: 85px;
  text-align: center;
  padding: 4px 7px 7px 7px;
  background-color: #1686b5;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  float: right;
}

.color-car{
  height: 30px;
  width: 85px;
  text-align: center;
  padding: 4px 7px 7px 7px;
  background-color: #000;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  float: right;
}

.category-car{
  height: 30px;
  width: 85px;
  text-align: center;
  padding: 4px 7px 7px 7px;
  background-color: #f37721;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  float: right;
}


.trips{
  background-image: linear-gradient(to right top, #048eb8, #0784a9, #0b799b, #0e6f8d, #10657f);
  height: 80px;
  padding: 23px;
  text-align: center;
  font-size: 21px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  font-weight: 600;
  color: #fff;
}

.volt{
  font-size: 24px;
  text-align: center;
  margin-top:5px
}

.fuel-img{
  height: 60px;
}

.charts{
  margin-left: -20px;
  margin-top: -24px;
}

.toggle-btn{
  float: left;
  margin-top: -13px;
  margin-left: 10px;
}

.toggle-btn1{
  float: left;
  margin-top: -4px;
  margin-left: 10px;
}


.dropdown-item.active, .dropdown-item:active {
  color: #343a40;
  text-decoration: none;
  background-color: #e5e8eb!important;
}

.img2{
  font-size: 43px;
  color: #fff;
  margin-top: 7px;
  margin-left: 22%;
}

.accordion .card-header {
  background-color: #fff !important;
  border: 0px !important;
  border-bottom: 1px solid #c4c2c2 !important;
  border-radius: none !important;
  margin-bottom:20px
}
.img3{
  height: 40px;
  margin-left: 30%;
  margin-top: 9px;
}

.img5{
  height: 33px;
  margin-left: 34%;
  margin-top: 7px;
}

.img6{
  height: 35px;
  margin-left: 27%;
  margin-top: 8px;
}

.icon-pacifics-head {
  border-bottom: 2px solid #048eb8;
  width: 85px;
  height: 2px;
  padding: 8px 17px 7px 14px;
}

.icon-pacifics-head1 {
  border-bottom: 2px solid #048eb8;
  width: 85px;
  margin-top: -11px;
  height: 2px;
  margin-bottom: 8px;
}

.btn-outline-secondary {
  color: #048eb8!important;
  border-color: #048eb8!important;
}

.btn-outline-secondary:hover {
  color: #fff!important;
  background-color: #048eb8!important;
  border-color: #048eb8!important;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #f37721!important;
  transition: width 0.6s ease;
}

.ng-select .ng-dropdown-panel .ng-option.ng-option-marked {
  background-color: #048eb8 !important;
  color: #fff !important;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #FA751A!important;
}

.pagination{float: right;}

.page-item.active .page-link{
  background-color: #FA751A!important;
    border-color: #FA751A!important;
}

.badgePackage{
  border: 1px solid #e6e3f2 !important;  width: 120px; display: inline-block!important; text-align: center; background-color: #e6e3f2; padding: 4px 15px; border-radius: 6px; margin: 0px 4px 4px 4px; display: inline-block;
}

.badgePackagePopup{
  border: 1px solid #e6e3f2 !important;  display: inline-block!important; text-align: center; background-color: #e6e3f2; padding: 4px 15px; border-radius: 6px; margin: 0px 4px 4px 4px; display: inline-block;
}

.moreBadgesIcons{align-self: flex-end; margin-bottom: -12px; margin-right: -8px;  margin-left: auto;}

 

.curvBtn{
  border-radius: 300px;
}
.width100{
  width:100px;
}

.pointerCursor{cursor: pointer;}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
  background-color: #048EB8!important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected{
  background-color: #048EB8!important;
} 

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{
  color: #000;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected :hover, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked:hover, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked:hover{
  background-color: #048EB8!important; 
}


.text-selected{
  color: #048eb8;
}


.ngb-dp-day, .ngb-dp-week-number, .ngb-dp-weekday{
  background-color: transparent!important;
}
.datePickerIconText{
  border-right: 0px!important;
}

.datePickerIcon{
 background-color: transparent!important;
}

.noPad{padding: 0px!important;}

.marLeftAuto{
  margin-left: auto;
  select{
    margin-left: 10px; width:170px!important;
  }
}

.algoImage{height: 50px; width: auto;}

.width135{width:135px;}

.minHeight351{
  min-height: 351px;
}

.minHeight366{
  min-height: 366px;
}

@media(max-width:786px){
  .leftpanel{
      display: none;
  }
}


.apexcharts-pie-label{
  font-size:9px; 
  } 


  .enrollAlgoContainer{height: 210px;}
  
  .apex-charts {
    min-height: 0px !important;
}

.card-body.minHeight179{
  height: 179px!important;   
}

.floatRight{
  float: right;
}

.width120{width: 120px;}
.mr-10{margin-right: 10px;} 

.insuranceDashboard{
  .headerBar{ 
border-radius: 4px;
background: #fff; padding: 6px;
border: 1px solid rgba(227, 227, 227, 0.69); margin-bottom: 16px;
    .col-sm-3{ padding: 8px 16px 8px 36px;

font-weight: 400;
font-size: 14px; line-height: 16px;
text-align: left;
color: #8a8a8a;border-right: 1px solid rgba(227, 227, 227, 0.69); 
    }
    .col-sm-3:last-child{
      border-right: 0;
    }
 }   
 .card{ 
  border-radius: 8px;
  background: #fff; 
  border: 1px solid rgba(227, 227, 227, 0.69);
  .card-body{
    padding: 6px!important;
    h5.card-title{ 
  
      font-weight: 500;
      font-size: 13px;
      text-align: left;
      color: #848484; text-transform: uppercase;
      border-bottom: 1px solid rgba(112,112,112, 0.20); padding: 4px 8px 8px 8px; 
      
    }
    .contentArea{
  
      font-weight: 500;
      font-size: 30px;
      text-align: right;
      color: #8a8a8a; 
      height: 104px; display: flex; justify-content: center; align-items: center;
     } 
  
     .apex-charts text{display: none;}
     .semiLargeSection{
       min-height:270px; padding: 10px; 
       .semiLargeSectionInner{
        height:250px;overflow: auto; 
       }
     
       
     }
     .semiLargeSection2{
      min-height:348px; padding: 10px; 
     }
     .capsule{background-color: #F1F1F1;margin-bottom: 8px; border-radius: 6px; display: flex; align-items: center;
      .leftPart{
    
        font-weight: 500;
        font-size: 14px;
        text-align: left;
        color: #757575; padding: 10px 10px 10px 16px; 
        flex-grow: 1;
      }
      .rightPart{
        width: 152px;
height: 51px;
flex-grow: 1;
border-radius: 6px; flex-grow: 1;
clip-path: polygon(33% 0, 100% 0, 100% 100%, 0% 100%); margin-left: auto;
font-weight: 500;
font-size: 18px;
text-align: right;
color: #fff;padding: 0 16px 0 10px; display: flex; align-items: center; justify-content: right; flex-grow: 1;

      }
      .rightPart.enrolled{
        background: #61c4ba;
      
      }
      .rightPart.activated{
        background: #61C47B;
      }
      .rightPart.pending{
        background: #F3B97E;
      }
      .rightPart.failed{
        background: #FF6D6D;
      } 
    }
    
  }          
 }

 .card.newCard{
    .card-body{
      padding: 0px!important;
      .card-title{
        margin: 0px;
      }
      .darkText{
        color: #FFFFFF!important;
      }
    }
 }

 .alertBar{
  font-size: 12px!important;
  background-color: #F5F5F5!important; border-radius: 8px!important; border-color: #F5F5F5;
 }
 
 .tripchart{
  height: 380px; padding: 10px;
  .totalNumber{
 
    font-size: 16px;
    text-align: left;
    color: #747474;
  }
 

 }
 .tripRightArea{
  height:78px ; display: flex; justify-content: center; align-items: center;
  .innerText{
    font-weight: 500;
    font-size: 28px; 
    color: #747474;
    
  }
}
.scrollContent{
  height: 117px;  
}
.infoBox{ 
text-align: left;
color: #8a8a8a; padding: 24px 16px;

}
.knobChartArea{
  height: 200px;
}
.progressionChart{
  height: 286px;
}
.knobChartArea.DriverScore{ 
  .apexcharts-text.apexcharts-datalabel-value{
    font-size: 32px; text-align: center!important;position: unset;  
  } 

}
.vehicleProfileCard{
  height: 100px;
}
.driverCoaching{
  height: 142px; padding: 10px; overflow: auto;
}
.vehicleProfileCard.darkText{
  display: flex; justify-content: center; align-items: center;

font-weight: 500;
font-size: 38px; 
color: #8a8a8a;
small{
  font-size: 10px!important; font-weight: 400;
}

}
.text-12{font-size: 12px;}
.circleBox{
  width: 54px; height: 54px; margin: 0 8px; color: #FFFFFF; border-radius: 200px; display: flex; justify-content: center; align-items: center;
}
.circleBox.blue{
  background-color: #88BDF2;
}
.circleBox.green{
  background-color: #67C86B;
}
.circleBox.orange{
  background-color: #E8894A;
}
.lineChartOnVehicleProfile{
  height: 304px; padding: 10px;
}
.configurationArea{
  padding: 0px 24px 10px 24px;
}
.custom-slider {padding: 8px;}  


.custom-slider .ngx-slider .ngx-slider-bubble{
  // display: none;
} 
.custom-slider .ngx-slider .ngx-slider-tick {  
  width: 1px;  
  height: 16px;  
  margin-left: 4px;  
  border-radius: 0; top:7px;  
  background: #ccc;  
}

.sliderAreaBlock{
  width: 70%; margin: 0 auto;  
}
.breadcrumb{
   align-items: center;
}
.noIcons{
  .breadcrumb-item +  .breadcrumb-item::before{
    display: none!important;
  }
}

.pad10{padding: 10px;}

.lineBlock{ margin-bottom: 10px;
  .lineArea{
    border-bottom: 1px solid rgba(112, 112, 112, 0.2)!important; padding: 8px; margin-bottom: 4px; color: #8A8A8A;
  }
  .lineArea:last-child{
    border-bottom: 0px!important;
  }
}
.impactIcon{
  width: 148px; height: 148px; margin: 0 auto; margin-bottom: 10px; background-image: url(../src/assets/images/impactIcon.png); background-repeat: no-repeat; background-position: left top; position: relative;
  .leftTopClip{
      position: absolute; left: 0; top: 0; width: 74px; height: 74px; background-image: url(../src/assets/images/impactIcon_leftTop.png); background-repeat: no-repeat; background-position: left top;  
  }
  .leftBottomClip{
    position: absolute; left: 0; bottom: 0; width: 74px; height: 74px; background-image: url(../src/assets/images/impactIcon_leftBottom.png);background-repeat: no-repeat; background-position: left top;  
    }
    .rightTopClip{
      position: absolute; right: 0; top: 0; width: 74px; height: 74px; background-image: url(../src/assets/images/impactIcon_rightTop.png);background-repeat: no-repeat; background-position: left top;  
    }
    .rightBottomClip{
      position: absolute; right: 0; bottom: 0; width: 74px; height: 74px; background-image: url(../src/assets/images/impactIcon_rightBottom.png);background-repeat: no-repeat; background-position: left top;  
    }
}
.crash .table > :not(caption) > * > * {
  padding: 8px 8px;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.weatherWidget{
  width: 320px;background-image: url(../src/assets/images/weatherWidget_top.png); background-repeat: no-repeat; background-position: left top;
  .weatherWidgetTop{
    width: 320px; height: 162px; padding: 16px 16px 16px 16px; color: #FFFFFF;  font-size: 14px;
    strong{
      font-weight: 400!important;
    }
    h2{
      font-size: 20px;color: #FFFFFF;padding: 16px 16px 16px 94px;
    }
  }
  .weatherContent{ font-size: 16px;
    background-color: #178baf;border-bottom-left-radius: 10px 10px;border-bottom-right-radius: 10px 10px; min-height:284px; padding: 24px 24px 24px 24px; color: #FFFFFF;
  }
}

.marginLeftAuto{
  margin-left: auto;
}

.accidentMapClass{width: 100%; height: auto;}
.text-10{font-size: 11px;}


}  
.menu-title.menu-pad{padding-top: 0px!important;padding-bottom: 0px!important;}
.decorationNone{
  .accordion .card-header{
    background-color: #F7F7F7;
  }
    button .btn.btn-link{
      text-decoration: none!important; color: #757575!important; 
    }
    .btn-link{
      color: #757575!important;  text-decoration: none!important;display: block!important; width: 100%!important;
      .vin {
        height: 30px;
        width: 65px;
        text-align: center;
        padding: 4px 7px 7px 7px;
        background-color: #00d5ce;
        border-radius: 5px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        float: right;
    }
    .vin-failed{
      height: 30px;
      width: 65px;
      text-align: center;
      padding: 4px 7px 7px 7px;
      background-color: #ff5947;
      border-radius: 5px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      float: right;
  }
  .request-pending{
        height: 30px;
        width: 65px;
        text-align: center;
        padding: 4px 7px 7px 7px;
        background-color: #1686b5;
        border-radius: 5px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        float: right;
    }
    }
    
    .btn-link:hover{
      color: #757575!important;  text-decoration: none!important;
    }
    .vinEnrollmentHeight{height: 250px;}
    
   
}
.tripDetailsBar{height: 200px;overflow: auto;}
.bgBlue{
  background-image: linear-gradient(to right top, #048eb8, #0784a9, #0b799b, #0e6f8d, #10657f); border-bottom-left-radius: 8px;border-bottom-right-radius: 8px;
}
 
 .apexcharts-tooltip {
  opacity: 1 !important;
}
.dFlexFull{
  display: flex; width: 100%;
}
.btnXtraSmall{
  padding: 2px 6px!important;
  font-size: 10px!important;  
}
.gForceChart{  
  .apexcharts-tooltip{opacity: 1!important;}
  .apexcharts-tooltip.apexcharts-theme-light{opacity: 1!important;}
  .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
      background: #E97B7B;
      border-bottom: 1px solid #E97B7B; color: #FFFFFF;
  }
}

#static-1-header button{
  display: block; width: 100%;
  span{
    float: left;
  }
}

#static-2-header button{
  display: block; width: 100%;
  span{
    float: left;display: inline; text-align: left;
  }
}

#static-3-header button{
  display: block; width: 100%;
  span{
    float: left; display: inline;
  }
}



.ng-sidebar-accordion__left-pane .ng-sidebar_opened .ng-sidebar-content, .ng-sidebar-accordion__right-pane .ng-sidebar_opened .ng-sidebar-content{
  background-color: #f5f5f5!important;
}

.configurationArea{
  padding: 0px 24px 10px 24px;
}
.custom-slider {padding: 8px;}  


.custom-slider .ngx-slider .ngx-slider-bubble{
  display: none;
} 
.custom-slider .ngx-slider .ngx-slider-tick {  
  width: 1px;  
  height: 16px;  
  margin-left: 4px;  
  border-radius: 0; top:7px;  
  background: #ccc;  
}

.sliderAreaBlock{
  width: 70%; margin: 0 auto;  
}


// .accordion .card-header {
//   padding: 0px!important;
//   background-color: #fff!important;
//   // border-bottom: 1px solid #e5e5e5!important;
//   border:0px !important;
//   border-bottom: 1px solid #c4c2c2 !important;
//   border-radius: none!important;
// }

.ng-sidebar-accordion__left-pane .ng-sidebar-content, .ng-sidebar-accordion__right-pane .ng-sidebar-content {
  width: 0;
  overflow: hidden;
  // width: 100%;
  transition: none!important
}

.ng-sidebar-accordion__left-pane .ng-sidebar-content, .ng-sidebar-accordion__right-pane .ng-sidebar-content {
  width: 0;
  overflow: hidden;
   transition: width var(--ng-sidebar-accordion-animation-duration); 
}

.ng-sidebar-accordion__left-pane .ng-sidebar_opened .ng-sidebar-content {
  max-width: 100%!important;
  width: 100%!important
}

.ng-sidebar-accordion__left-pane .ng-sidebar_opened .ng-sidebar-content, .ng-sidebar-accordion__right-pane .ng-sidebar_opened .ng-sidebar-content {
  transition: none!important;
  width: 100%!important;
}

.ng-sidebar-accordion__right-pane .ng-sidebar_opened .ng-sidebar-content {
  max-width: 100%!important;
  width: 100%!important
}


.ng-sidebar-accordion__left-pane .ng-sidebar_opened .ng-sidebar-content, .ng-sidebar-accordion__right-pane .ng-sidebar_opened .ng-sidebar-content {
  transition: var(--ng-sidebar-accordion-animation-duration);
  width: 100%!important;
}

.accordion .card {
  margin-bottom: 15px;
  box-shadow: none;
  border: none!important;
  color: #000 !important;
  // border-bottom: 1px solid #000!important;
}

.ng-sidebar-accordion .ng-sidebar-header:after{
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  background-color: #f37721 !important;
  color: #fff !important;
}
 
